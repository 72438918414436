import Rest from '@/services/Rest';

/**
 * @typedef {BillingsAvailableForGenerateReferences}
 */
export default class BillingsAvailableForGenerateReferences extends Rest {
    /**
     * @type {String}
     */
    static resource = '/uau/billing-avalilable-for-generate-references'
}
