import Rest from '@/services/Rest';

/**
 * @typedef {AllPaymentsBilling}
 */
export default class AllPaymentsBilling extends Rest {
    /**
     * @type {String}
     */
    static resource = '/uau/all-payments-billing'
}
