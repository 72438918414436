import Rest from '@/services/Rest';

/**
 * @typedef {Unit}
 */
export default class Unit extends Rest {
    /**
     * @type {String}
     */
    static resource = '/uau/unities'
}
