<template>
<div class="col-6 col-md-4 col-lg-3 p-2 p-sm-2">

      <div
        class=""
        id="billing"
        v-on:click="listBillings();openRight()"    
        href="#"
        >
        <div class="bg-white bder-default p-4 mouse-cta-dash">
       
            <div>
                <div class="mb-3">
                <img
                    src="@/assets/img/ico-qs-01.png"
                    class=""
                    height="25"
                    alt="Qsuíte"
                />
                </div>
                <div>
                <br class="d-none d-md-block" />
                <h2
                    class="mb-1 font-weight-medium title-dash-cliente"
                >
                    2ª via de<br />
                    boleto
                </h2>
                </div>
                <div class="text-right">
                <img
                    src="@/assets/img/ico-qs-07.png"
                    class=""
                    height="14"
                    alt="Qsuíte"
                />
                </div>
            </div>
        
        </div>
    </div>  



        <div
        id="right-modal-billing"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        style="display: none"
        aria-modal="true"
        >
            <div class="modal-dialog modal-md modal-right h-100">
                <div class="modal-content">
                    <div class="modal-header border-0">
                        <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        @click="closeRight()"
                        aria-hidden="true"
                        >
                        <i class="ti-close font-20"></i>
                        </button>
                    </div>
                <div class="modal-body mt-5 pt-5 p-0" style="width: auto;">  
                    <div class="w-100 mx-auto d-flex align-self-center">
                        <div class="align-items-center mr-3 ml-3 mt-5">
                            <div v-if="error !== '' && spinner == false">
                                <p>{{error}}</p>
                            </div>

                            <div v-if="error == '' && spinner == false">
                                <div v-if="billings.length >=1">
                                 <b class="mb-5 mt-2"> Boletos:</b>
                                    <div class="mt-2" v-for="billing in billings">                                      
                                        <p>Data de emissão: {{billing["data_emissao"]}} -   Data de vencimento: {{billing["data_vencimento"]}}</p>
                                        <p>Valor: R$ {{formatMoney(billing["valor_documento"])}}</p>
                                        <p>Status: <b :style="billing['status_titulo'] == 'ATRASADO'? 'color: red' : 'color: blue'">{{billing["status_titulo"]}}</b></p> 
                                        <p class="bg-primary pr-3 pl- pt-3 pb-3 text-center w-50 text-white" @click="downloadBoleto(billing['codigo_lancamento_integracao'])">Baixar boleto</p>
                                     <hr />
                                    </div>                                 
                                </div>
                                <div v-else>
                                    <p>Não foram encontrados boletos</p>     
                                </div>
                        </div>
                    </div>                    
                </div>
                    <div v-if="spinner == true">
                        <div class="mx-auto h-100 d-flex align-self-center">
                            <div class="align-items-center">
                            <Spinner size="80" />
                            </div>
                            <div class="align-self-center">
                            <b class="mb-0 font-12 font-b-4" style="color: #232323"
                                >Aguarde alguns instantes enquanto o sistema localiza
                                todos os documentos. Pode levar alguns segundos...</b
                            >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
</template>

<script>
import BillingAvailableForGenerate from "@/services/resources/Omie/BillingAvailableForGenerate";
import BillingAvailableGenerate from "@/services/resources/Omie/BillingAvailableGenerate";

import Spinner from "@/components/Spinner.vue";
import Money from "@/mixins/money";

let billingAvailableForGenerate = BillingAvailableForGenerate.build()
let billingGenerate = BillingAvailableGenerate.build();

export default {
  components: { Spinner },
  name: "OmieBillings",
  mixins:[Money],
  data() {
    return {
        spinner: false,
        error:"",
        billings: []
    }
  },

    methods: {
        listBillings() {
            let prmt = { id: this.$store.getters.getProfile.id };
              this.spinner = true
            billingAvailableForGenerate.create(prmt).then(function(re) {
                this.spinner = false
                console.log(re);
                this.billings = re
                this.error = ""
            }.bind(this)).catch(function(error) {
                this.error = "Houve um erro ao acessar os boletos. Tente novamente"
                this.spinner = false
            }.bind(this))
        },
        downloadBoleto(boleto) {
             this.spinner = true
            billingGenerate
                .read( boleto)
                .then(function(re) {
                    console.log(re);
                    if (re.erro != null && re.erro != "") {
                            this.$bvToast.toast(
                          re.erro,
                            {
                            title: "Erro",
                            autoHideDelay: 5000,
                            type: "error",
                            }
                        );
                    }
                    this.spinner = false                     
                }.bind(this)).catch(function(error) {
                    this.spinner = false
                      this.$bvToast.toast(
                            "Houve um erro ao buscar seu boleto",
                            {
                            title: "Erro",
                            autoHideDelay: 5000,
                            type: "error",
                            }
                        );
                }.bind(this));            
        },
        openRight() {
            jQuery(document).ready(function () {
                jQuery("#right-modal-billing").modal("show");             
           });

        },
        closeRight() {
            jQuery(document).ready(function () {
                jQuery("#right-modal-billing").modal("hide");
            });    
        }
    },

    mounted() {
      
    }

}
</script>