import Rest from '@/services/Rest';

/**
 * @typedef {IncomingTaxService}
 */
export default class IncomingTaxService extends Rest {
    /**
     * @type {String}
     */
    static resource = '/uau/income-tax'
}
