<template>
  <div v-show="!loading" class="w-100">
    <div class="container-fluid py-2 p-dash-cliente-master" style="background: #f6f6f7">
      <div class="col-12">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-9">
              <div class="row py-2">
                <div
                  class="col-12 p-2 p-sm-2 order-3 order-sm-1"
                  style="max-width: 1080px"
                >
                  <div class="col-12 py-2 text-center">
                    <h3
                      class="font-28 font-b-6 mb-1"
                      style="color: var(--color)"
                    >
                      {{ theme.sentence }}
                    </h3>
                  </div>
                  <div class="d-none d-md-block pr-0 pr-lg-4">
                    <img
                      v-show="theme.image_desktop.length"
                      :src="theme.image_desktop"
                      class="img-fluid bder-default"
                      title="Banner"
                    />
                  </div>
                  <div class="d-md-none d-flex">
                    <img
                      v-show="theme.image_mobile.length"
                      :src="theme.image_mobile"
                      class="img-fluid bder-default"
                      title="Banner"
                    />
                  </div>
                </div>

                <div class="col-12 px-2 px-sm-3 pt-3 order-1 order-sm-2">
                  <div class="">
                    <span class="font-16 font-b-5 span-ola mb-1">
                      Olá, {{ user.first_name }}!</span
                    >
                    <h3
                      class="page-title font-b-6 mb-1"
                      style="color: var(--color)"
                    >
                      O que você procura?
                    </h3>
                  </div>
                </div>

                <div
                  class="col-12 col-md-11 col-lg-11 order-2 order-sm-3"
                  style="max-width: 1080px"
                >
                  <div class="row">
                    <div
                      v-show="permissions.avaliacao_satisfacao"
                      class="col-6 col-md-4 col-lg-3 p-2 p-sm-2"
                    >
                      <div
                        class="bder-default p-4 d-flex mouse-cta-dash"
                        style="background-color: var(--color)"
                      >
                        <a href="?app=financeiro.php" title="">
                          <div>
                            <div class="mb-3">
                              <img
                                src="@/assets/img/ico-qs-09.png"
                                class=""
                                height="18"
                                alt="Qsuíte"
                              />
                            </div>
                            <div>
                              <br class="d-none d-md-block" />
                              <h2
                                class="mb-0 font-weight-medium title-dash-cliente text-white"
                              >
                                Avalie seu nível<br />
                                de satisfação
                              </h2>
                            </div>
                            <div class="mb-1" style="height: 23px">
                              <p
                                class="m-0 p-0 text-white"
                                style="font-size: 9px"
                              >
                                Sua experiência aqui é muito importante para
                                nós.
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <template v-if="source == 'UAU'" v-show="permissions.segunda_via_boleto">
                      <div                      
                        class="col-6 col-md-4 col-lg-3 p-2 p-sm-2"
                        id="kit2"
                        @click="getEnterprises()"
                        href="#"
                      >
                        <div class="bg-white bder-default p-4 mouse-cta-dash">
                          <a
                            data-toggle="modal"
                            data-target="#right-modal-kit2"
                            href="#"
                          >
                            <div>
                              <div class="mb-3">
                                <img
                                  src="@/assets/img/ico-qs-01.png"
                                  class=""
                                  height="25"
                                  alt="Qsuíte"
                                />
                              </div>
                              <div>
                                <br class="d-none d-md-block" />
                                <h2
                                  class="mb-1 font-weight-medium title-dash-cliente"
                                >
                                  2ª via de<br />
                                  boleto
                                </h2>
                              </div>
                              <div class="text-right">
                                <img
                                  src="@/assets/img/ico-qs-07.png"
                                  class=""
                                  height="14"
                                  alt="Qsuíte"
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>     
                    </template> 
                    <template v-if="source =='OMIE'"   v-show="permissions.segunda_via_boleto">
                        <OmieBillings></OmieBillings>
                    </template>          
                    <div
                      class="col-6 col-md-4 col-lg-3 p-2 p-sm-2"
                      id="kit3"
                      @click="getUnits()"
                    >
                      <div class="bg-white bder-default p-4 mouse-cta-dash">
                        <a
                          data-toggle="modal"
                          data-target="#right-modal-kit3"
                          href="#"
                        >
                          <div>
                            <div class="mb-3">
                              <img
                                src="@/assets/img/ico-qs-02.png"
                                class=""
                                height="25"
                                alt="Qsuíte"
                              />
                            </div>

                            <div>
                              <br class="d-none d-md-block" />
                              <h2
                                class="mb-1 font-weight-medium title-dash-cliente"
                              >
                                Extrato<br />
                                financeiro
                              </h2>
                            </div>
                            <div class="text-right">
                              <img
                                src="@/assets/img/ico-qs-07.png"
                                class=""
                                height="14"
                                alt="Qsuíte"
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div
                      v-show="permissions.informe_rendimento"
                      class="col-6 col-md-4 col-lg-3 p-2 p-sm-2"
                      id="kit4"
                      @click="getUnits()"
                    >
                      <div class="bg-white bder-default p-4 mouse-cta-dash">
                        <a
                          data-toggle="modal"
                          data-target="#right-modal-kit4"
                          href="#"
                        >
                          <div>
                            <div class="mb-3">
                              <img
                                src="@/assets/img/ico-qs-03.png"
                                class=""
                                height="25"
                                alt="Qsuíte"
                              />
                            </div>
                            <div>
                              <br class="d-none d-md-block" />
                              <h2
                                class="mb-1 font-weight-medium title-dash-cliente"
                              >
                                Informe de<br />
                                rendimentos
                              </h2>
                            </div>
                            <div class="text-right">
                              <img
                                src="@/assets/img/ico-qs-07.png"
                                class=""
                                height="14"
                                alt="Qsuíte"
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div
                      v-show="permissions.assistencia_tecnica"
                      class="col-6 col-md-4 col-lg-3 p-2 p-sm-2"
                    >
                      <div class="bg-white bder-default p-4 mouse-cta-dash">
                        <router-link :to="{ name: 'Report' }">
                          <div>
                            <div class="mb-3">
                              <img
                                src="@/assets/img/ico-qs-06.png"
                                class=""
                                height="25"
                                alt="Qsuíte"
                              />
                            </div>
                            <div>
                              <br class="d-none d-md-block" />
                              <h2
                                class="mb-1 font-weight-medium title-dash-cliente"
                              >
                                Assistência<br />
                                técnica
                              </h2>
                            </div>
                            <div class="text-right">
                              <img
                                src="@/assets/img/ico-qs-07.png"
                                class=""
                                height="14"
                                alt="Qsuíte"
                              />
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                    <div
                      v-show="permissions.kit_acabamento"
                      class="col-6 col-md-4 col-lg-3 p-2 p-sm-2"
                    >
                      <router-link :to="{ name: 'KitWelcome' }">
                        <div class="bg-white bder-default p-4 mouse-cta-dash">
                          <a href="?app=financeiro.php" title="">
                            <div>
                              <div class="mb-3">
                                <img
                                  src="@/assets/img/ico-qs-05.png"
                                  class=""
                                  height="25"
                                  alt="Qsuíte"
                                />
                              </div>
                              <div>
                                <br class="d-none d-md-block" />
                                <h2
                                  class="mb-1 font-weight-medium title-dash-cliente"
                                >
                                  Personalize o<br />
                                  apartamento
                                </h2>
                              </div>
                              <div class="text-right">
                                <img
                                  src="@/assets/img/ico-qs-07.png"
                                  class=""
                                  height="14"
                                  alt="Qsuíte"
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      </router-link>
                    </div>
                    <div
                      v-show="permissions.acompanhamento_obra"
                      class="col-6 col-md-4 col-lg-3 p-2 p-sm-2"
                    >
                      <div class="bg-white bder-default p-4 mouse-cta-dash">
                        <a href="?app=financeiro.php" title="">
                          <div>
                            <div class="mb-3">
                              <img
                                src="@/assets/img/ico-qs-04.png"
                                class=""
                                height="25"
                                alt="Qsuíte"
                              />
                            </div>
                            <div>
                              <br class="d-none d-md-block" />
                              <h2
                                class="mb-1 font-weight-medium title-dash-cliente"
                              >
                                Evolução<br />
                                da obra
                              </h2>
                            </div>
                            <div class="text-right">
                              <img
                                src="@/assets/img/ico-qs-07.png"
                                class=""
                                height="14"
                                alt="Qsuíte"
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-3 p-0">
              <div class="col-12 p-2 p-sm-2" v-if="permissions.kit_acabamento">
                <div class="col-12 pt-3">
                  <h2
                    class="mb-1 font-weight-medium title-dash-ar"
                    style="color: var(--color)"
                  >
                    Personalização
                  </h2>
                </div>
                <div class="col-12 p-0">
                  <div class="bder-default pt-3" style="background-color: #fff">
                    <div class="col-12 px-3 pb-3">
                      <!-- <a href="?app=financeiro.php" title=""> -->
                      <div
                        v-for="items in enterprises_information"
                       
                      >
                      <div 
                        v-for="(unity, id) 
                        in items.unities" 
                        class="d-flex align-self-center py-3"
                      >
                      <router-link  v-if="id == 0 && unity?.unity?.order == null && unity?.unity?.order?.is_active == 0" :to="'/kit/unidade/v2?enterprise='+unity.unity.enterprise.id+'&unity='+unity.unity.id">
                          <div class="align-self-center pr-3" >
                            <div
                              class="d-flex align-items-center text-center"
                              style="
                                height: 60px;
                                width: 60px;
                                border-radius: 200px;
                                background-color: #f6f6f7;
                              "
                            >
                               <img
                                :src="unity.unity.enterprise.image"
                                class="img-fluid p-2"
                                alt="Qsuíte"
                              /> 
                            </div>
                          
                         
                              <div class="align-self-center">
                                <h5 class="mb-0 title-dash-ar-inc pt-2">
                                   <b>{{ unity.unity.enterprise.name}} - Unidade: {{unity.unity.number}}</b> 
                                </h5>
                                <div class="progress barra-progress">
                                  <div
                                    class="progress-bar"
                                    role="progressbar"
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style="background-color: var(--color); !important; width: 30%; height:2px"
                                  ></div>
                                </div>
                                <p
                                  class="mb-0"
                                  style="color: #949494; font-size: 12px"
                                >
                                  Acompanhe o processo de personalização.
                                </p>
                              </div>
                            </div>
                            </router-link>

                          <router-link  v-if="unity?.unity?.order == null" :to="'/kit/unidade/v2?enterprise='+unity.unity.enterprise.id+'&unity='+unity.unity.id">
                          <div class="align-self-center pr-3" >
                            <div
                              class="d-flex align-items-center text-center"
                              style="
                                height: 60px;
                                width: 60px;
                                border-radius: 200px;
                                background-color: #f6f6f7;
                              "
                            >
                               <img
                                :src="unity.unity.enterprise.image"
                                class="img-fluid p-2"
                                alt="Qsuíte"
                              /> 
                            </div>
                          
                         
                              <div class="align-self-center">
                                <h5 class="mb-0 title-dash-ar-inc pt-2">
                                   <b>{{ unity.unity.enterprise.name}} - Unidade: {{unity.unity.number}}</b> 
                                </h5>
                                <div class="progress barra-progress">
                                  <div
                                    class="progress-bar"
                                    role="progressbar"
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style="background-color: var(--color); !important; width: 30%; height:2px"
                                  ></div>
                                </div>
                                <p
                                  class="mb-0"
                                  style="color: #949494; font-size: 12px"
                                >
                                  Acompanhe o processo de personalização.
                                </p>
                              </div>
                            </div>
                            </router-link>
                        </div>
                      </div>
                     
                      <!-- </a> -->
                    </div>
                    <div class="col-12" style="border-top: 1px #e7e7e7 solid">
                      <div class="row align-items-center">
                        <div
                          class="col-6 p-3"
                          style="border-right: 1px #e7e7e7 solid"
                        >
                          <router-link to="/kit/arquivos-personalizacao">
                            <h5
                              class="mb-0 font-10 font-b-6"
                              style="line-height: 1.5"
                            >
                              ARQUIVOS DE <br />PERSONALIZAÇÃO
                            </h5>
                          </router-link>
                        </div>
                        <div class="col-6 p-3">
                          <router-link to="/kit/minha-personalizacao">
                            <div
                              class="d-flex mx-auto align-items-center"
                              style="width: max-content"
                            >
                              <h5 class="mb-0 font-10 font-b-6">MINHAS </br>PERSONALIZAÇÕES</h5>
                              <img
                                src="@/assets/img/ico-qs-10.png"
                                height="13"
                                class="ml-2"
                                alt="Qsuíte"
                              />
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-12 p-2 p-sm-2"
                v-if="permissions.assistencia_tecnica && reports.length"
              >
                <div class="col-12 pt-3">
                  <h2
                    class="mb-1 font-weight-medium title-dash-ar"
                    style="color: var(--color)"
                  >
                    Visita técnica
                  </h2>
                </div>

                <div class="col-12 p-0">
                  <div class="bder-default pt-3" style="background-color: #fff">
                    <router-link :to="'/chamados/editar/' + reports[0].id">
                      <div class="col-12 px-3 pb-3">
                        <a href="?app=financeiro.php" title="">
                          <div class="d-flex align-self-center">
                            <div class="align-self-center text-center px-3">
                              <h5
                                class="mb-0 font-b-4 font-60"
                                style="color: #232323; line-height: 50px"
                              >
                                10
                              </h5>
                              <p
                                class="mb-0"
                                style="color: #949494; font-size: 12px"
                              >
                                JUL.
                              </p>
                            </div>
                            <div class="align-self-center w-100">
                              <div class="col-12 p-0 text-right">
                                <p
                                  class="mb-0 font-b-5"
                                  style="color: #949494; font-size: 12px"
                                >
                                  {{ reports[0].status }}
                                </p>
                              </div>
                              <p
                                class="mb-0 font-b-4"
                                style="color: #949494; font-size: 9px"
                              >
                                HORÁRIO
                              </p>
                              <h5 class="mb-0 title-dash-ar-inc font-b-6">
                                {{ reports[0].date }}
                              </h5>
                              <p
                                class="mb-0 font-b-4 pt-2"
                                style="color: #949494; font-size: 9px"
                              >
                                MOTIVO
                              </p>
                              <h5 class="mb-0 title-dash-ar-inc font-b-6">
                                {{ reports[0].subject }}
                              </h5>
                              <div class="d-flex pt-2">
                                <div
                                  class="align-self-center"
                                  v-show="reports[0].technical"
                                >
                                  <img
                                    src="@/assets/img/users/profile-pic.jpg"
                                    class="ico-dash-ar-tec"
                                    alt="Qsuíte"
                                  />
                                </div>
                                <div
                                  class="align-self-center pl-2"
                                  v-show="reports[0].technical"
                                >
                                  <p
                                    class="mb-0 font-b-4"
                                    style="color: #949494; font-size: 9px"
                                  >
                                    TÉCNICO
                                  </p>
                                  <h5
                                    class="mb-0 font-b-6"
                                    style="color: #949494; font-size: 12px"
                                  >
                                    {{
                                      reports[0].technical
                                        ? reports[0].technical.first_name
                                        : ""
                                    }}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </router-link>
                    <div class="col-12" style="border-top: 1px #e7e7e7 solid">
                      <div class="row">
                        <div
                          class="col-6 p-3 text-center"
                          style="border-right: 1px #e7e7e7 solid"
                        >
                          <router-link to="/chamados">
                            <h5
                              class="mb-0 font-12 font-b-6"
                              style="color: #aaa9a9"
                            >
                              TODOS
                            </h5>
                          </router-link>
                        </div>
                        <div class="col-6 py-3">
                          <router-link to="/chamados/novo">
                            <div
                              class="d-flex mx-auto align-items-center"
                              style="width: max-content"
                            >
                              <h5 class="mb-0 font-12 font-b-6">
                                ABRIR CHAMADO
                              </h5>
                              <img
                                src="@/assets/img/ico-qs-07.png"
                                height="13"
                                class="ml-2"
                                alt="Qsuíte"
                              />
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 p-2 p-sm-2">
                <div class="col-12 pt-3">
                  <h2
                    class="mb-1 font-weight-medium title-dash-ar"
                    style="color: var(--color)"
                  >
                    Ações rápidas
                  </h2>
                </div>
                <div class="col-12 p-3">
                  <router-link to="/perfil">
                    <h5 class="mb-0 font-b-6 font-16">Atualizar cadastro</h5>
                  </router-link>
                </div>
                <!-- <div class="col-12 p-3" style="border-top: 1px #e1e1e1 solid;">
                            <a href="?app=financeiro.php" title="">
                                <h5 class="mb-0 font-b-6 font-16">Meus documentos</h5>
                            </a>
                        </div>
                        <div class="col-12 p-3" style="border-top: 1px #e1e1e1 solid;">
                            <a href="?app=financeiro.php" title="">
                                <h5 class="mb-0 font-b-6 font-16">Dúvidas frequentes</h5>
                            </a>
                        </div> -->
              </div>

              <div class="col-12 p-2 p-sm-2">
                <div class="col-12 d-atividades-r pt-3">
                  <h2 class="mb-1 font-weight-medium title-dash-ar">
                    Atividades recentes
                  </h2>
                </div>
                <div
                  class="col-12 d-atividades-r"
                  v-for="item in reports"
                  :key="item.id"
                >
                  <router-link
                    :to="{ name: 'ReportEdit', params: { id: item.id } }"
                    class="d-atividades-a"
                  >
                    <div class="d-atividades-cta d-flex">
                      <div class="d-flex align-self-center">
                        <div class="align-self-center">
                          <!-- <img
                            v-if="item.enterprise.image"
                            :src="item.enterprise.image"
                            class="ico-dash-ar"
                            alt="Qsuíte"
                          /> -->
                        </div>
                        <div class="align-self-center">
                          <h5 class="mb-0 title-dash-ar-inc">
                            {{ item.enterprise.name }} -
                            <b>{{ item.subject }}</b>
                          </h5>
                        </div>
                      </div>
                      <div class="ml-auto d-dash-ar-2 align-self-center">
                        <img
                          src="~@/assets/img/icons/mais.png"
                          class="img-fluid ico-dash-ar-2"
                          alt="Qsuíte"
                        />
                      </div>
                    </div>
                  </router-link>
                </div>
                <div
                  v-if="reports.length == 0"
                  class="row justify-content-center"
                >
                  Sem atividades recentes.
                </div>
                <div class="col-12 text-center" style="margin-bottom: 20px">
                  <router-link :to="{ name: 'Report' }" class="font-20 a-link-1"
                    ><i class="icon-options"></i
                  ></router-link>
                </div>
              </div>
              <!-- <div class="col-12 p-2 p-sm-2">
                        <div class="col-12 pt-3">
                            <h2 class="mb-1 font-weight-medium title-dash-ar" style="color: var(--color);">Atividades recentes</h2>
                        </div>
                        <div class="col-12 p-0">
                            <div class="bder-default p-3" style="background-color: #fff;">
                                <a href="?app=financeiro.php" title="">
                                    <div class="d-flex align-self-center">
                                        <div class="align-self-center pr-3">
                                            <div class="d-flex align-items-center text-center" style="height:60px; width:60px; border-radius:200px; background-color: #f6f6f7;">
                                                <img src="@/assets/img/logo-gsuite.png" class="img-fluid p-2" alt="Qsuíte">
                                            </div>
                                        </div>
                                        <div class="align-self-center">
                                            <h5 class="mb-0 title-dash-ar-inc"><b>HUB_ COMPACT LIFE</b></h5>
                                            <p class="mb-0" style="color:#949494; font-size:12px;">Boleto junho 21 | <b>R$ 1.980,25</b></p>
                                        </div>
                                        <div class="align-self-center ml-auto">
                                            <img src="@/assets/img/ico-qs-07.png" class="" height="14" alt="Qsuíte">
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="p-3">
                                <a href="?app=financeiro.php" title="">
                                    <div class="d-flex align-self-center">
                                        <div class="align-self-center pr-3">
                                            <div class="d-flex align-items-center text-center" style="height:60px; width:60px; border-radius:200px; background-color: #f6f6f7;">
                                                <img src="@/assets/img/logo-gsuite.png" class="img-fluid p-2" alt="Qsuíte">
                                            </div>
                                        </div>
                                        <div class="align-self-center">
                                            <h5 class="mb-0 title-dash-ar-inc"><b>HUB_ COMPACT LIFE</b></h5>
                                            <p class="mb-0" style="color:#949494; font-size:12px;">Boleto junho 21 | <b>R$ 1.980,25</b></p>
                                        </div>
                                        <div class="align-self-center ml-auto">
                                            <img src="@/assets/img/ico-qs-07.png" class="" height="14" alt="Qsuíte">
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="p-3" style="border-top: 1px #e1e1e1 solid;">
                                <a href="?app=financeiro.php" title="">
                                    <div class="d-flex align-self-center">
                                        <div class="align-self-center pr-3">
                                            <div class="d-flex align-items-center text-center" style="height:60px; width:60px; border-radius:200px; background-color: #f6f6f7;">
                                                <img src="@/assets/img/logo-gsuite.png" class="img-fluid p-2" alt="Qsuíte">
                                            </div>
                                        </div>
                                        <div class="align-self-center">
                                            <h5 class="mb-0 title-dash-ar-inc"><b>HUB_ COMPACT LIFE</b></h5>
                                            <p class="mb-0" style="color:#949494; font-size:12px;">Boleto junho 21 | <b>R$ 1.980,25</b></p>
                                        </div>
                                        <div class="align-self-center ml-auto">
                                            <img src="@/assets/img/ico-qs-07.png" class="" height="14" alt="Qsuíte">
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div> -->
            </div>
          </div>
        </div>
      </div>

      <div
        id="right-modal-kit2"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        style="display: none"
        aria-modal="true"
      >
        <div class="modal-dialog modal-md modal-right modal-autoheight">
          <div class="modal-content">
            <div class="modal-header border-0">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                <i class="ti-close font-20"></i>
              </button>
            </div>
            <div class="modal-body p-0" style="width: auto; height: 100vh">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 py-3 px-4">
                    <h2 class="mb-1 font-weight-medium font-18">
                      2ª via de boleto
                    </h2>
                  </div>
                  <div class="col-12 px-0" v-if="enterprises.length > 1">
                    <select
                      v-model="enterpriseSelected"
                      class="w-100 custom-select-empreendimento"
                      style="
                        background-color: #f7f7f7;
                        border: 0;
                        height: 60px;
                        padding-right: 20px;
                        padding-left: 20px;
                        font-size: 11px;
                      "
                    >
                      <option :value="null">SELECIONE O EMPREENDIMENTO</option>
                      <option
                        v-for="enterprise in enterprises"
                        :key="enterprise.Empresa"
                        :value="enterprise"
                      >
                        {{ enterprise.DescricaoEmpresa }}
                      </option>
                    </select>
                  </div>

                  <hr
                    class="m-0"
                    style="
                      height: 1px;
                      background-color: #e8e8e8;
                      width: 100%;
                      border: 0;
                    "
                  />

                  <div v-if="enterprises.length < 1">
                    <div class="w-100 mx-auto d-flex align-self-center">
                      <div class="align-items-center">
                        <Spinner size="80" />
                      </div>
                      <div class="align-self-center">
                        <b class="mb-0 font-12 font-b-4" style="color: #232323"
                          >Aguarde alguns instantes enquanto o sistema localiza
                          todos os documentos. Pode levar alguns segundos...</b
                        >
                      </div>
                    </div>
                  </div>
          
                  <div v-if="showSpinnerBillingsAvailable == true">
                    <div class="w-100 mx-auto d-flex align-self-center">
                      <div class="align-items-center">
                        <Spinner size="80" />
                      </div>
                      <div class="align-self-center">
                        <b class="mb-0 font-12 font-b-4" style="color: #232323"
                          >Aguarde alguns instantes enquanto o sistema localiza
                          todos os documentos. Pode levar alguns segundos...</b
                        >
                      </div>
                    </div>
                  </div>

                  <template v-if="showSpinnerBillingsAvailable == false">
                    <div
                      v-for="(billing, index) in billings_available"
                      :key="index"
                      class="bg-white col-12 p-0"
                    >
                      <hr
                        class="m-0"
                        style="
                          height: 1px;
                          background-color: #e8e8e8;
                          width: 100%;
                          border: 0;
                        "
                      />
                      <div
                        class="col-12 px-4 py-3"
                        style="background-color: #ececec"
                      >
                        <b
                          class="mb-0 font-16 font-b-7"
                          style="color: #232323"
                          >{{ billing.name }}</b
                        >
                      </div>
                      <hr
                        class="m-0"
                        style="
                          height: 1px;
                          background-color: #e8e8e8;
                          width: 100%;
                          border: 0;
                        "
                      />

                      <div
                        class="col-12 px-4 py-3"
                        v-for="item in billing.billings"
                        :key="item.seuNumero"
                      >
                        <div class="d-flex align-self-center py-1">
                          <div class="align-self-center pr-2">
                            <h5 class="mb-0 title-dash-ar-inc">
                              {{ formatDate(item.dataVencimento) }}
                            </h5>
                          </div>
                          <div class="align-self-center">
                            <h5 class="mb-0 title-dash-ar-inc font-12">
                              {{ item.referencia.split("***")[0] }} -
                              <b>{{
                                item.referencia
                                  .split("***")[1]
                                  .replace("UNIDADE", "UNID.")
                              }}</b>
                            </h5>
                          </div>
                          <div class="align-self-center ml-auto">
                            <h5 class="mb-0 font-16 font-b-5">
                              {{
                                parseFloat(item.valorDocumento).toLocaleString(
                                  "pt-BR",
                                  {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "BRL",
                                  }
                                )
                              }}
                            </h5>
                          </div>
                        </div>
                        <div class="col-12 px-0 text-right d-flex">
                          <div class="align-self-center ml-auto">
                            <a href="#">
                              <div
                                class="d-flex align-items-center"
                                @click="copy(index)"
                              >
                              

                                <h5 class="mb-0 font-10">Linha digitável</h5>
                                <i
                                  class="fas fa-barcode pl-2"
                                  style="color: #6c757d"
                                ></i>
                              </div>
                           
                            </a>
                          </div>
                          <div class="d-flex align-self-center  pl-4">
                            <a
                              href="#"
                              @click="
                                getBillingPdf(
                                  item.codBanco,
                                  item.seuNumero,
                                  `${billing.name}_${item.referencia
                                    .split('***')[1]
                                    .replace('UNIDADE', 'UNID.')}`
                                )
                              "
                            >
                              <div class="d-flex align-items-center">
                                <h5 class="mb-0 font-10">2ª via de boleto</h5>
                                <img
                                  src="@/assets/img/ico-qs-11.png"
                                  class="pl-2"
                                  height="25"
                                  alt="Qsuíte"
                                />

                                <template
                                  v-if="showSpinnerBillingId === item.seuNumero"
                                >
                                  <Spinner />
                                </template>
                              </div>
                            </a>
                          </div>
                       
                        </div>
                             <input
                                  v-on:focus="$event.target.select()"
                                  type="text"
                                  style="opacity:0"
                                  ref="typeable-line"
                                  v-model="item.linhaDigitavel"
                                />

                        <hr
                          class="m-0"
                          style="
                            height: 1px;
                            background-color: #e8e8e8;
                            width: 100%;
                            border: 0;
                          "
                        />
                      </div>
                    </div>
                  </template>

                  <template
                    v-if="!showSpinnerBillingsAvailable && enterpriseSelected"
                  >
                    <hr
                      class="m-0"
                      style="
                        height: 1px;
                        background-color: #e8e8e8;
                        width: 100%;
                        border: 0;
                      "
                    />

                    <div class="col-12 px-4 py-2 bg-white">
                      <h5 class="mb-0 title-dash-ar-inc">
                        <b>Parcelas futuras</b>
                      </h5>
                    </div>

                    <div
                      v-show="!all_payments_billings.length"
                      class="w-100 mx-auto d-flex align-self-center"
                    >
                      <div class="align-self-center p-3 text-center col-12">
                        <b class="mb-0 font-12 font-b-4" style="color: #232323"
                          >2ª via de boleto não disponível para está data.</b
                        >
                      </div>
                    </div>

                    <div
                      v-for="(payment, index) in all_payments_billings"
                      :key="index"
                      class="bg-white"
                      style="width: 100%"
                    >
                      <template>
                        <hr
                          class="m-0"
                          style="
                            height: 1px;
                            background-color: #e8e8e8;
                            width: 100%;
                            border: 0;
                          "
                        />
                        <div class="col-12 px-4 py-2">
                          <div class="d-flex align-self-center py-2">
                            <div class="align-self-center pr-2">
                              <h5 class="mb-0 title-dash-ar-inc">
                                {{ formatDate(payment.DataVencimento) }}
                              </h5>
                            </div>
                            <div class="align-self-center">
                              <h5 class="mb-0 title-dash-ar-inc">
                                {{ payment.Descricao }} -
                                <b
                                  >[{{ payment.NumParc }}/{{
                                    payment.TotalParcela
                                  }}]</b
                                >
                              </h5>
                            </div>
                            <div class="align-self-center ml-auto">
                              <h5 class="mb-0 font-16 font-b-5">
                                {{
                                  parseFloat(
                                    payment.ValorParcela
                                  ).toLocaleString("pt-BR", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "BRL",
                                  })
                                }}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </template>

                      <hr
                        class="m-0"
                        style="
                          height: 1px;
                          background-color: #e8e8e8;
                          width: 100%;
                          border: 0;
                        "
                      />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  -->

      <div
        id="right-modal-kit3"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        style="display: none"
        aria-modal="true"
      >
        <div class="modal-dialog modal-md modal-right modal-autoheight">
          <div class="modal-content">
            <div class="modal-header border-0">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                <i class="ti-close font-20"></i>
              </button>
            </div>
            <div class="modal-body p-0" style="width: auto; height: 100vh">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 py-3 px-4">
                    <h2 class="mb-1 font-weight-medium font-18">
                      Extrato financeiro
                    </h2>
                  </div>
                  <div
                    class="col-12 px-0"
                    v-if="this.all_units_by_enterprise.length > 1"
                  >
                    <select
                      v-model="enterpriseSelectedsStatement"
                      class="w-100 custom-select-empreendimento"
                      style="
                        background-color: #f7f7f7;
                        border: 0;
                        height: 60px;
                        padding-right: 20px;
                        padding-left: 20px;
                        font-size: 11px;
                      "
                    >
                      <option :value="null">SELECIONE O EMPREENDIMENTO</option>
                      <option
                        v-for="enterprise in enterprises"
                        :key="enterprise.Empresa"
                        :value="enterprise"
                      >
                        {{ enterprise.DescricaoEmpresa }}
                      </option>
                    </select>
                  </div>

                  <hr
                    class="m-0"
                    style="
                      height: 1px;
                      background-color: #e8e8e8;
                      width: 100%;
                      border: 0;
                    "
                  />

                  <div v-if="!this.all_units_by_enterprise.length">
                    <div class="w-100 mx-auto d-flex align-self-center">
                      <div class="align-items-center">
                        <Spinner size="80" />
                      </div>
                      <div class="align-self-center">
                        <b class="mb-0 font-12 font-b-4" style="color: #232323"
                          >Aguarde alguns instantes enquanto o sistema localiza
                          todos os documentos. Pode levar alguns segundos...</b
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    v-for="(
                      enterprise, index
                    ) in all_units_by_enterprise_filtereds"
                    :key="index"
                    class="bg-white col-12 p-0"
                  >
                    <hr
                      class="m-0"
                      style="
                        height: 1px;
                        background-color: #e8e8e8;
                        width: 100%;
                        border: 0;
                      "
                    />
                    <div
                      class="col-12 px-4 py-3"
                      style="background-color: #ececec"
                    >
                      <b class="mb-0 font-16 font-b-7" style="color: #232323">{{
                        enterprise.name
                      }}</b>
                    </div>
                    <hr
                      class="m-0"
                      style="
                        height: 1px;
                        background-color: #e8e8e8;
                        width: 100%;
                        border: 0;
                      "
                    />

                    <div
                      class="col-12 px-4 py-3 bg-white"
                      v-for="(item, index) in enterprise.unities"
                      :key="index"
                    >
                      <div class="d-flex align-self-center py-1">
                        <div class="align-self-center pr-2">
                          <!-- <h5 class="mb-0 title-dash-ar-inc">{{formatDate(item.dataVencimento)}}</h5> -->
                        </div>
                        <div class="align-self-center">
                          <h5 class="mb-0 title-dash-ar-inc font-12">
                            <b>UNID. {{ item.Identificador_unid }}</b>
                            <!-- {{item.referencia.split('***')[0]}}  - <b>{{item.referencia.split('***')[1].replace('UNIDADE', 'UNID.')}}</b> -->
                          </h5>
                        </div>
                        <div class="align-self-center ml-auto">
                          <a href="#">
                            <div
                              class="d-flex align-items-center"
                              @click="getStatementPdf(item)"
                            >
                              <h5 class="mb-0 font-10">Baixar extrato</h5>
                              <img
                                src="@/assets/img/ico-qs-11.png"
                                class="pl-2"
                                height="25"
                                alt="Qsuíte"
                              />

                              <template v-if="showSpinnerId === item.Num_Ven">
                                <Spinner />
                              </template>
                            </div>
                          </a>
                        </div>
                      </div>

                      <hr
                        class="m-0"
                        style="
                          height: 1px;
                          background-color: #e8e8e8;
                          width: 100%;
                          border: 0;
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  -->
      <div
        id="right-modal-kit4"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        style="display: none"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-md modal-right modal-autoheight">
          <div class="modal-content">
            <div class="modal-header border-0">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                <i class="ti-close font-20"></i>
              </button>
            </div>
            <div class="modal-body p-0" style="width: auto; height: 672px">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 py-3 px-4">
                    <h2 class="mb-1 font-weight-medium font-18">
                      Informe de rendimentos
                    </h2>
                  </div>

                  <div class="col-12 px-0" v-if="enterprises.length > 1">
                    <select
                      v-model="enterpriseSelectedsIncomeTax"
                      class="w-100 custom-select-empreendimento"
                      style="
                        background-color: #f7f7f7;
                        border: 0;
                        height: 60px;
                        padding-right: 20px;
                        padding-left: 20px;
                        font-size: 11px;
                      "
                    >
                      <option :value="null">SELECIONE O EMPREENDIMENTO</option>
                      <option
                        v-for="enterprise in enterprises"
                        :key="enterprise.Empresa"
                        :value="enterprise"
                      >
                        {{ enterprise.DescricaoEmpresa }}
                      </option>
                    </select>
                  </div>

                  <div v-if="enterprises.length" class="col-12 px-0">
                    <select
                      v-model="yearsIncomeTaxSelected"
                      class="w-100 custom-select-empreendimento"
                      style="
                        background-color: #f7f7f7;
                        border: 0;
                        height: 60px;
                        padding-right: 20px;
                        padding-left: 20px;
                        font-size: 11px;
                      "
                    >
                      <option :value="null">SELECIONE O ANO DO INFORME</option>
                      <option
                        v-for="(year, index) in yearsIncomeTax"
                        :key="index"
                        :value="year"
                      >
                        {{ year }}
                      </option>
                    </select>
                  </div>

                  <div v-if="!enterprises.length">
                    <div class="w-100 mx-auto d-flex align-self-center">
                      <div class="align-items-center">
                        <Spinner size="80" />
                      </div>
                      <div class="align-self-center">
                        <b class="mb-0 font-12 font-b-4" style="color: #232323"
                          >Aguarde alguns instantes enquanto o sistema localiza
                          todos os documentos. Pode levar alguns segundos...</b
                        >
                      </div>
                    </div>
                  </div>

                  <!--  -->

                  <template
                    v-if="
                      yearsIncomeTaxSelected && enterpriseSelectedsIncomeTax
                    "
                  >
                    <div
                      v-for="(
                        enterprise, index
                      ) in all_units_by_enterprise_filtereds"
                      :key="index"
                      class="bg-white col-12 p-0"
                    >
                      <hr
                        class="m-0"
                        style="
                          height: 1px;
                          background-color: #e8e8e8;
                          width: 100%;
                          border: 0;
                        "
                      />
                      <div
                        class="col-12 px-4 py-3"
                        style="background-color: #ececec"
                      >
                        <b
                          class="mb-0 font-16 font-b-7"
                          style="color: #232323"
                          >{{ enterprise.name }}</b
                        >
                      </div>
                      <hr
                        class="m-0"
                        style="
                          height: 1px;
                          background-color: #e8e8e8;
                          width: 100%;
                          border: 0;
                        "
                      />

                      <div
                        class="col-12 px-4 py-3 bg-white"
                        v-for="(item, index) in enterprise.unities"
                        :key="index"
                      >
                        <div class="d-flex align-self-center py-1">
                          <div class="align-self-center pr-2">
                            <!-- <h5 class="mb-0 title-dash-ar-inc">{{formatDate(item.dataVencimento)}}</h5> -->
                          </div>
                          <div class="align-self-center">
                            <h5 class="mb-0 title-dash-ar-inc font-12">
                              <b>UNID. {{ item.Identificador_unid }}</b>
                              <!-- {{item.referencia.split('***')[0]}}  - <b>{{item.referencia.split('***')[1].replace('UNIDADE', 'UNID.')}}</b> -->
                            </h5>
                          </div>
                          <div class="align-self-center ml-auto">
                            <a href="#" @click="getIncomeTaxPdf(item)">
                              <div class="d-flex align-items-center">
                                <h5 class="mb-0 font-10">Baixar informe</h5>
                                <img
                                  src="@/assets/img/ico-qs-11.png"
                                  class="pl-2"
                                  height="25"
                                  alt="Qsuíte"
                                />

                                <template v-if="showSpinnerId === item.Num_Ven">
                                  <Spinner />
                                </template>
                              </div>
                            </a>
                          </div>
                        </div>

                        <hr
                          class="m-0"
                          style="
                            height: 1px;
                            background-color: #e8e8e8;
                            width: 100%;
                            border: 0;
                          "
                        />
                      </div>
                    </div>
                  </template>

                  <!--  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
    .mouse-cta-dash:hover {
        border: 1px solid var(--color);
    }
</style>

<script>
import ReportService from "@/services/resources/ReportService";
import AllPaymentsBilling from "@/services/resources/Uau/AllPaymentsBilling";
import EnterpriseService from "@/services/resources/Uau/EnterpriseService";
import IncomingTaxService from "@/services/resources/Uau/IncomingTaxService";
import BillingsAvailableForGenerate from "@/services/resources/Uau/BillingsAvailableForGenerate";
import BillingsAvailableForGenerateReferences from "@/services/resources/Uau/BillingsAvailableForGenerateReferences";
import BillingAvailableGenerate from "@/services/resources/Uau/BillingAvailableGenerate";
import PaymentStatementGenerate from "@/services/resources/Uau/PaymentStatementGenerate";
import UnitService from "@/services/resources/Uau/Unit";
import moment from "moment";
import Spinner from "../../components/Spinner.vue";
import OmieBillings from "../../components/OmieBillings.vue";

import PermissionService from "@/services/resources/PermissionService";
import ThemeService from "@/services/resources/ThemeService";
import ClientEnterpriseService from "@/services/resources/ClientEnterpriseService";

let clientEnterpriseService = ClientEnterpriseService.build();
const serviceTheme = ThemeService.build();
const servicePermission = PermissionService.build();
const service = ReportService.build();
const enterprise = EnterpriseService.build();
const billingsAvailable = BillingsAvailableForGenerate.build();
const billingsAvailableReferences =BillingsAvailableForGenerateReferences.build();
const billingGenerate = BillingAvailableGenerate.build();
const PaymentStatement = PaymentStatementGenerate.build();
const allPaymentsBilling = AllPaymentsBilling.build();
const unit = UnitService.build();
const IncomingTax = IncomingTaxService.build();
export default {
  name: "Financial",
  data() {
    return {
      enterprises_information: [],
      reports: [],
      enterprises: [],
      billings_available: [],
      all_billings_available: [],
      itemMenuChange: "",
      enterpriseSelectedsIncomeTax: null,
      enterpriseSelected: null,
      enterpriseSelectedsStatement: null,
      enterpriseSelectedInconeTax: null,
      all_payments_billings: [],
      showSpinnerBillingId: false,
      showSpinnerBillingsAvailable: false,
      showLoadingStatement: false,
      actualYear: new Date().getFullYear(),
      yearsIncomeTaxSelected: null,
      all_units_by_enterprise: [],
      all_units_by_enterprise_filtereds: [],
      showSpinnerId: null,
      permissions: {
        segunda_via_boleto: false,
        avaliacao_satisfacao: false,
        kit_acabamento: false,
        assistencia_tecnica: false,
        acompanhamento_obra: false,
        extrato_financeiro: false,
        informe_rendimento: false,
      },
      loading: true,
      theme: {
        sentence: "",
        image_mobile: "",
        image_desktop: "",
      },
      source: ""
    };
  },
  watch: {
    enterpriseSelectedsIncomeTax() {
      if (
        this.enterpriseSelectedsIncomeTax.DescricaoEmpresa ===
        "TODOS EMPREENDIMENTOS"
      ) {
        this.all_units_by_enterprise_filtereds = this.all_units_by_enterprise;

        return;
      }

      if (this.enterpriseSelectedsIncomeTax) {
        for (let i = 0; i < this.all_units_by_enterprise.length; i++) {
          if (
            this.all_units_by_enterprise[i].name ===
            this.enterpriseSelectedsIncomeTax.DescricaoEmpresa
          ) {
            this.all_units_by_enterprise_filtereds = [
              this.all_units_by_enterprise[i],
            ];
            break;
          }
        }
      } else {
        this.all_units_by_enterprise_filtereds = [];
      }
    },
    enterpriseSelectedsStatement() {
      if (
        this.enterpriseSelectedsStatement.DescricaoEmpresa ===
        "TODOS EMPREENDIMENTOS"
      ) {
        this.all_units_by_enterprise_filtereds = this.all_units_by_enterprise;

        return;
      }

      if (this.enterpriseSelectedsStatement) {
        for (let i = 0; i < this.all_units_by_enterprise.length; i++) {
          if (
            this.all_units_by_enterprise[i].name ===
            this.enterpriseSelectedsStatement.DescricaoEmpresa
          ) {
            this.all_units_by_enterprise_filtereds = [
              this.all_units_by_enterprise[i],
            ];
            break;
          }
        }
      } else {
        this.all_units_by_enterprise_filtereds = [];
      }
    },

    async enterpriseSelected() {
      if (this.enterpriseSelected) {
        this.showSpinnerBillingsAvailable = true;
      }
      let response = this.enterprises;

      if (!this.all_billings_available.length) {
        let params = {};
        if (
          this.enterpriseSelected.DescricaoEmpresa !== "TODOS EMPREENDIMENTOS"
        ) {
          params["company_id"] = this.enterpriseSelected.Empresa;
        }

        const billings = await billingsAvailable.search(params);
        for (let i = 0; i < billings.length; i++) {
          for (let j = 0; j < response.length; j++) {
            if (+billings[i].codEmpresa === +response[j].Empresa) {
              response[j].DescricaoEmpresa = this.formatEnterpriseName(
                response[j].DescricaoEmpresa,
                billings[i].instrucao
              );

              break;
            }
          }
        }
        if (response.length === 1) {
          this.enterpriseSelected = response[0];
        }
        this.getAllPaymentsBillings();
        const billings_formated = this.formatBillingsAvailable(
          billings,
          this.enterprises
        );
        this.billings_available = billings_formated;
        this.showSpinnerBillingsAvailable = false;
      }
    },
  },
  created() {},
  methods: {
    fetchTheme() {
      let data = {
        domain: window.location.hostname,
      };
      serviceTheme
        .search(data)
        .then((resp) => {
          resp.forEach((element, index) => {
            switch (element.key) {
              case "PORTAL_CLIENT_SENTENCE_LOGGED":
                this.theme.sentence = element.value;
                break;
              case "PORTAL_CLIENT_BACKGROUND_DESKTOP_LOGGED":
                this.theme.image_desktop = element.value;
                break;
              case "PORTAL_CLIENT_BACKGROUND_MOBILE_LOGGED":
                this.theme.image_mobile = element.value;
                break;
            }
          });
        })
        .catch((err) => {});
    },
    fetchClientEnterprises() {
      let data = { id: this.$store.getters.getProfile.id };
      clientEnterpriseService
        .search(data)
        .then((re) => {
          this.enterprises_information = re.enterprises;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchReports() {
      service
        .search(data)
        .then((resp) => {
          this.reports = resp.data.map((item) => {
            return {
              ...item,
              status:
                item.status === "PENDING"
                  ? "Pendente"
                  : item.status === "FINISHED"
                  ? "Finalizado"
                  : "Em andamento",
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPermissions() {
     // servicePermission.search().then((response) => {
        // console.log(response);
        let count = 0;
        this.$store.getters.getProfile.company.permissions.forEach((permission) => {
          permission = permission.permission || permission;
          let permissionName = "";
          if (permission != null) {
            permissionName = permission.name;
          }
          switch (permissionName) {
            case "Kit Acabamento":
              count++;
              this.permissions.kit_acabamento = true;
              break;
            case "Assistência Técnica":
              count++;
              this.permissions.assistencia_tecnica = true;
              break;
            case "Acompanhamento de obra":
              count++;
              this.permissions.acompanhamento_obra = true;
              break;
            case "2° via de boleto":
              count++;
              this.permissions.segunda_via_boleto = true;
              break;
            case "Extrato financeiro":
              count++;
              this.permissions.extrato_financeiro = true;
              break;
            case "Informe de rendimentos":
              count++;
              this.permissions.informe_rendimento = true;
              break;
            case "Avaliação de satisfação":
              count++;
              this.permissions.avaliacao_satisfacao = true;
              break;
          }
        });

        if (count === 1 && this.permissions.kit_acabamento) {              
            this.$router.push({ name: "KitUnityV2" });             
        }
        this.loading = false;
    //  });
    },
    async getUnits() {

      if (!this.enterprises.length) {   
        let response = await enterprise.get("");

        const billings = await billingsAvailableReferences.get("");
        
        let enterpriseTreated = [];
        for (let i = 0; i < billings.length; i++) {
          for (let j = 0; j < response.length; j++) {
            if (+billings[i].codEmpresa === +response[j].Empresa) {
              response[j].DescricaoEmpresa = this.formatEnterpriseName(
                response[j].DescricaoEmpresa,
                billings[i].instrucao
              );
              enterpriseTreated.push(response[j]);
              break;
            }
          }
        }
        enterpriseTreated = enterpriseTreated.filter((item, i) => {
          return !enterpriseTreated.some(
            (item2, j) => j > i && item.Empresa === item2.Empresa
          );
        });
        if (enterpriseTreated.length > 1) {
          enterpriseTreated.push({
            ...enterpriseTreated[enterpriseTreated.length],
            DescricaoEmpresa: "TODOS EMPREENDIMENTOS",
          });
        }
        this.enterprises = enterpriseTreated;
      }
      if (!this.all_units_by_enterprise.length) {
        const unities = await unit.get("");
        console.log(unities);
        this.all_units_by_enterprise = this.formatUnits(unities);
      }
      if (this.enterprises.length === 1) {
        this.enterpriseSelected = this.enterprises[0];
        this.enterpriseSelectedsStatement = this.enterprises[0];
        this.enterpriseSelectedsIncomeTax = this.enterprises[0];
      }
    },
    async getEnterprises() {
      if (!this.enterprises.length) {
        let response = await enterprise.get("");

        let enterpriseTreated = response.map((item) => {
          return {
            ...item,
            DescricaoEmpresa: item.DescricaoEmpresa,
          };
        });
        // for(let i = 0; i < billings.length; i ++) {
        //     for(let j = 0; j < response.length; j ++) {

        //         if(+billings[i].codEmpresa === +response[j].Empresa) {
        //             response[j].DescricaoEmpresa = this.formatEnterpriseName(response[j].DescricaoEmpresa, billings[i].instrucao);
        //             enterpriseTreated.push(response[j]);
        //             break;
        //         }
        //     }
        // }
        enterpriseTreated = enterpriseTreated.filter((item, i) => {
          return !enterpriseTreated.some(
            (item2, j) => j > i && item.Empresa === item2.Empresa
          );
        });
        if (enterpriseTreated.length > 1) {
          enterpriseTreated.push({
            ...enterpriseTreated[enterpriseTreated.length],
            DescricaoEmpresa: "TODOS EMPREENDIMENTOS",
          });
        } else if (enterpriseTreated.length === 1) {
          this.enterpriseSelected = enterpriseTreated[0];
        }

        this.enterprises = enterpriseTreated;
      }
    },
    formatBillingsAvailable(billings, enterprises) {
      let billingsTreated = [];
      let billingTreated = null;

      for (let i = 0; i < enterprises.length; i++) {
        billingTreated = {
          ...enterprises[i],
          name: enterprises[i].DescricaoEmpresa,
          billings: [],
        };
        for (let j = 0; j < billings.length; j++) {
          if (+enterprises[i].Empresa === +billings[j].codEmpresa) {
            billingTreated.billings.push(billings[j]);
          }
        }
        if (billingTreated.billings.length) {
          billingsTreated.push(billingTreated);
        }
      }
      return billingsTreated;
    },
    formatUnits(unities) {
      let unities_formateds = [];
      for (let i = 0; i < this.enterprises.length; i++) {
        let unity = {
          name: this.enterprises[i].DescricaoEmpresa,
          unities: [],
        };
        for (let j = 0; j < unities.length; j++) {
          if (unities[j].Empresa_ven === this.enterprises[i].Empresa) {
            unity.unities.push({
              ...unities[j],
              name_enterprise: this.enterprises[i].DescricaoEmpresa,
            });
          }
        }
        unities_formateds.push(unity);
      }

      return unities_formateds;
    },

    formatEnterpriseName(descricaoEmpresa, instruction = null) {
      //SOLUÇÃO RÁPIDA
      return descricaoEmpresa;
      if (instruction && instruction.length) {
        const enterpriseName = instruction.split("[Total de Parcela]");
        instruction = instruction[instruction.length - 1];
        instruction = instruction.split("\n");
        return enterpriseName[enterpriseName.length - 1]
          .split(" ")
          .join("")
          .split("-")
          .join("");
      }
      return instruction;
    },
    getAllPaymentsBillings() {
      let params = {
        company_id: this.enterpriseSelected.Empresa,
        construction_id: this.enterpriseSelected.Obra,
        sale_id: this.enterpriseSelected.Empresa,
      };
      allPaymentsBilling
        .search(params)
        .then((response) => {
          const today = new Date().toJSON().slice(0, 10);
          let payments_billings_treated = [];

          response.forEach((item) => {
            if (item.DataVencimento > today + "T00:00:00") {
              payments_billings_treated.push(item);
            }
          });

          if (!payments_billings_treated.length) {
            this.$bvToast.toast(
              "Sem parcelas futuras disponíveis para este empreendimento",
              {
                title: "Erro",
                autoHideDelay: 5000,
                type: "error",
              }
            );
          } else {
            this.all_payments_billings = payments_billings_treated;
          }
        })
        .catch((err) => {
          this.all_payments_billings = [];
          this.$bvToast.toast(
            "Sem parcelas futuras disponíveis para este empreendimento",
            {
              title: "Erro",
              autoHideDelay: 5000,
              type: "error",
            }
          );
        });
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    fetchReports() {
      let data = {
        page: 1,
        per_page: 5,
      };
      service
        .search(data)
        .then((resp) => {
          this.reports = resp.data.map((item) => {
            return {
              ...item,
              status:
                item.status === "PENDING"
                  ? "Pendente"
                  : item.status === "FINISHED"
                  ? "Finalizado"
                  : "Em andamento",
            };
          });
        })
        .catch((err) => {});
    },
    copy(index) {
      this.$refs["typeable-line"][0].focus();
      console.log(this.$refs);
      try {
        document.execCommand("copy");
        this.$bvToast.toast("Copiado!", {
          title: "Código do boleto copiado.",
          autoHideDelay: 5000,
          type: "success",
        });
      } catch (err) {
        this.$bvToast.toast("Erro ao copiar.", {
          title: "Não foi possível copiar o código do boleto.",
          autoHideDelay: 5000,
          type: "error",
        });
      }
    },
    async getEnterprisesBillingsAvailable() {   
      let re
      re = await enterprise.get("");    
      this.enterprises = re
      console.log(this.enterprises);
      this.$forceUpdate()
      
      if (!this.all_billings_available.length) {
        const billings = await billingsAvailable.get("");
        let enterpriseTreated = [];
        for (let i = 0; i < billings.length; i++) {
          for (let j = 0; j < this.enterprises.length; j++) {
            if (+billings[i].codEmpresa === +this.enterprises[j].Empresa) {
              this.enterprises[j].DescricaoEmpresa = this.formatEnterpriseName(
                this.enterprises[j].DescricaoEmpresa,
                billings[i].instrucao
              );
              enterpriseTreated.push(this.enterprises[j]);
              break;
            }
          }
        }
        enterpriseTreated = enterpriseTreated.filter((item, i) => {
          return !enterpriseTreated.some(
            (item2, j) => j > i && item.Empresa === item2.Empresa
          );
        });
        if (this.enterprises.length == 1) {
          this.enterpriseSelected = this.enterprises[0];
           this.getAllPaymentsBillings();
        }
       

        const billings_formated = this.formatBillingsAvailable(
          billings,
          enterpriseTreated
        );

        enterpriseTreated.push({
          ...enterpriseTreated[enterpriseTreated.length],
          DescricaoEmpresa: "TODOS EMPREENDIMENTOS",
        });
        this.enterprises = enterpriseTreated;
        this.billings_available = billings_formated;
        this.all_billings_available = billings_formated;
        this.showSpinnerBillingsAvailable = false;
        this.$forceUpdate()
      }
      this.$forceUpdate()
    },
    getBillingPdf(bank_code, id, name) {
      let params = {
        bank_code,
      };
      this.showSpinnerBillingId = id;
      billingGenerate
        .download("uau/billing-generate", params, id, name + ".pdf")
        .finally(() => {
          this.showSpinnerBillingId = false;
        });
    },
    getStatementPdf(item) {
      let params = {
        company_id: item.Empresa_ven,
        construction_id: item.Obra_Ven,
        sale_id: item.Num_Ven,
      };
      this.showSpinnerId = item.Num_Ven;
      this.showLoadingStatement = true;
      PaymentStatement.download(
        "uau/payment-statement",
        params,
        null,
        `Extrato_${item.name_enterprise}_UNID_${item.Identificador_unid}.pdf`
      )
        .finally(() => {
          this.showSpinnerId = null;
        })
        .catch((err) => {
          this.$bvToast.toast(
            "Sem demonstrativo de pagamento disponível para este imóvel",
            {
              title: "Erro",
              autoHideDelay: 5000,
              type: "error",
            }
          );
        });
    },
    getIncomeTaxPdf(item) {
      let params = {
        company_id: item.Empresa_ven,
        construction_id: item.Obra_Ven,
        sale_id: item.Num_Ven,
        year: this.yearsIncomeTaxSelected,
      };
      this.showSpinnerId = item.Num_Ven;
      this.showLoadingStatement = true;
      IncomingTax.download(
        "uau/income-tax",
        params,
        null,
        `Informe_${item.name_enterprise}_UNIDADE_${item.Identificador_unid}__${this.yearsIncomeTaxSelected}.pdf`
      )
        .finally(() => {
          this.showSpinnerId = null;
          this.showLoadingStatement = false;
        })
        .catch((err) => {
          this.$bvToast.toast(
            "Sem Informe de pagamento disponível para este imóvel",
            {
              title: "Erro",
              autoHideDelay: 5000,
              type: "error",
            }
          );
        });
    },
  },

  mounted() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    this.getPermissions();
    this.fetchReports();
    this.fetchClientEnterprises();
    this.fetchTheme();
    jQuery(document).ready(function () {
      jQuery("#right-modal-kit button.close").click(function () {
        this.enterpriseSelected = null;
        jQuery("#right-modal-kit").modal("hide");
      });
    });
    jQuery(document).ready(function () {
      jQuery("#menu-product button.close").click(function () {
        this.enterpriseSelected = null;
        jQuery("#menu-product").modal("hide");
      });
    });
    jQuery(document).ready(function () {
      jQuery("#right-modal-kit2 button.close").click(function () {
        this.enterpriseSelected = null;
        jQuery("#right-modal-kit2").modal("hide");
      });
    });
    jQuery(document).ready(function () {
      jQuery("#right-modal-kit3 button.close").click(function () {
        this.enterpriseSelected = null;
        jQuery("#right-modal-kit3").modal("hide");
      });
    });
    //===  SHOW  ===
    jQuery(document).ready(function () {
      jQuery("#right-modal-kit3 button.open").click(function () {
        jQuery("#right-modal-kit3").modal("show");
      });
    });
    jQuery(document).ready(function () {
      jQuery("#right-modal-kit4 button.close").click(function () {
        jQuery("#right-modal-kit4").modal("hide");
      });
    });
    jQuery(document).ready(function () {
      jQuery("#kit2").click(function () {
        jQuery("#right-modal-kit2").modal("show");
      });
    });
    jQuery(document).ready(function () {
      jQuery("#kit3").click(function () {
        jQuery("#right-modal-kit3").modal("show");
      });
    });
    jQuery(document).ready(function () {
      jQuery("#kit4").click(function () {
        jQuery("#right-modal-kit4").modal("show");
      });
    });
    $(function () {
      if ($(".modal-autoheight").length > 0) {
        $(".modal").on("show.bs.modal", () => {
          var winHeight = $(window).height();
          $(".modal-autoheight .modal-body").css({
            width: "auto",
            height: winHeight - 0 + "px",
          });
        });
        $(window).on("resize", () => {
          var winHeight = $(window).height();
          $(".modal-autoheight .modal-body").css({
            width: "auto",
            height: winHeight - 0 + "px",
          });
        });
      }
    });
   this.source =  this.user["company"]["integrations"][0]["name"]
  },
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
    yearsIncomeTax() {
      let yearTreated = [];
      let firstYear = this.actualYear - 2;
      for (let i = this.actualYear; i >= firstYear; i--) yearTreated.push(i);
      return yearTreated;
    },
  },
  components: { Spinner,  OmieBillings },
};
</script>

